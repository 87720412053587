import React, {Component} from 'react';
export default class IMgsi extends React.Component {
    constructor(props){

        super(props);

    }
    mouse ={
        _x: 0,
        _y: 0,
        x: 0,
        y: 0,
        updatePosition: function (event) {
            let e = event || window.event;
            this.x = e.clientX - this._x;
            this.y = (e.clientY - this._y) * -1;
        },
        setOrigin: function (e) {
            this._x =
                e.offsetLeft +
                Math.floor(e.offsetWidth / 2);
            this._y =
                e.offsetTop +
                Math.floor(e.offsetHeight / 2);
        },
        show: function () {
            return "(" + this.x + ", " + this.y + ")";
        },
        
    };
    onMouseEnterHandler = function (event,el) {
        this.update(event,el);
    };
    onMouseLeaveHandler = function (el) {
        el.style = "";
    };
    onMouseMoveHandler = function (event,el) {
        if (this.isTimeToUpdate()) {
            this.update(event,el);
        }
    };
    counter = 0;
    updateRate = 10;
    isTimeToUpdate = function () {
        return this.counter++ % this.updateRate === 0;
    };
    update = function (event,el) {
        this.mouse.updatePosition(event);
        this.updateTransformStyle(
            (this.mouse.y / el.offsetHeight /2).toFixed(2),
            (this.mouse.x / el.offsetWidth /2).toFixed(2),
            el
        );
    };
   updateTransformStyle = function (x, y, el) {
        let style =
            "rotateX(" + x*7 + "deg) rotateY(" + y *2 + "deg)";
        el.style.transform = style;
        el.style.webkitTransform = style;
        el.style.mozTransform = style;
        el.style.msTransform = style;
        el.style.oTransform = style;
    };
    render() {
      return <div
      onMouseEnter={e=>this.onMouseEnterHandler(e,document.querySelector(`.${this.props.img.props.className}`))}
      onMouseLeave={e=>this.onMouseLeaveHandler(document.querySelector(`.${this.props.img.props.className}`))}
      onMouseMove={e=>this.onMouseMoveHandler(e,document.querySelector(`.${this.props.img.props.className}`))}
            style={{ top: this.props.top, left: this.props.left }}
            className="imgHov"
        >
                {this.props.img}
        </div>;
    }
}
