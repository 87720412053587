import { useEffect } from "react";
import '../css/customers.css';
export default function Modorniz({ stepEnd }) {
    function beforeAfter() {
        document.getElementById("kobavenusab").style.width =
            document.getElementById("pedsumid").value + "%";
    }
    useEffect(() => {
        let customers_switch = document.querySelectorAll(".customers_switch")
        let customers_icon_box_mini = document.querySelectorAll(".customers_icon_box_mini")
        let customers_icon_box = document.querySelectorAll(".customers_icon_box")
        customers_switch.forEach((e,i)=>{
            e.addEventListener('click', function (event) {
                switch(i) {
                    case 0: 
                        
                        customers_icon_box_mini.forEach(e=>{e.classList.add("transition"); })
                        
                        customers_switch.forEach((el,i)=>{ el.classList.remove("active_switch")})
                        customers_switch[0].classList.add("active_switch");
                        setTimeout(() => {
                            customers_icon_box.forEach((el,i)=>{ el.classList.remove("active_box")})
                            customers_icon_box_mini.forEach(e=>{e.classList.remove("transition"); }); 
                            customers_icon_box[0].classList.add("active_box");
                    }, 500);
                        
                        
        
                      break;
                  
                    case 1: 
                    customers_icon_box_mini.forEach(e=>{e.classList.add("transition"); })
                        
                    customers_switch.forEach((el,i)=>{ el.classList.remove("active_switch")})
                    customers_switch[1].classList.add("active_switch");
                    setTimeout(() => {
                        customers_icon_box.forEach((el,i)=>{ el.classList.remove("active_box")})
                        customers_icon_box_mini.forEach(e=>{e.classList.remove("transition"); }); 
                        customers_icon_box[1].classList.add("active_box");
                }, 500);
                        break;
                    case 2:   
                    customers_icon_box_mini.forEach(e=>{e.classList.add("transition"); })
                        
                    customers_switch.forEach((el,i)=>{ el.classList.remove("active_switch")})
                    customers_switch[2].classList.add("active_switch");
                    setTimeout(() => {
                        customers_icon_box.forEach((el,i)=>{ el.classList.remove("active_box")})
                        customers_icon_box_mini.forEach(e=>{e.classList.remove("transition"); }); 
                        customers_icon_box[2].classList.add("active_box");
                }, 500);
                        break;
                    case 3:
                        customers_icon_box_mini.forEach(e=>{e.classList.add("transition"); })
                        
                        customers_switch.forEach((el,i)=>{ el.classList.remove("active_switch")})
                        customers_switch[3].classList.add("active_switch");
                        setTimeout(() => {
                            customers_icon_box.forEach((el,i)=>{ el.classList.remove("active_box")})
                            customers_icon_box_mini.forEach(e=>{e.classList.remove("transition"); }); 
                            customers_icon_box[3].classList.add("active_box");
                    }, 500);
                        break;
                    case 4: 
                    customers_icon_box_mini.forEach(e=>{e.classList.add("transition"); })
                        
                    customers_switch.forEach((el,i)=>{ el.classList.remove("active_switch")})
                    customers_switch[4].classList.add("active_switch");
                    setTimeout(() => {
                        customers_icon_box.forEach((el,i)=>{ el.classList.remove("active_box")})
                        customers_icon_box_mini.forEach(e=>{e.classList.remove("transition"); }); 
                        customers_icon_box[4].classList.add("active_box");
                }, 500);
                        break;
                    default:
                     
                      break;
                  }
            })
        })
    }, [])
    
    return (
        <section id="modern" className="modern ekr-js">
            <div className="container">
                <h2 className="h2">
                    С нами работают{" "}
                </h2>
                <div className="strategy_switch_box">
                    <div className="strategy_switch customers_switch active_switch">
                        Фабрики и производства
                    </div>
                    <div className="strategy_switch customers_switch">
                        Промышленные предприятия
                    </div>
                    <div className="strategy_switch customers_switch">
                        Сфера услуг
                    </div>
                    <div className="strategy_switch customers_switch">
                        Государственные учреждения, МУП
                    </div>
                    <div className="strategy_switch customers_switch">
                        E-commerce решения и <br /> 1С
                        Битрикс
                    </div>
                </div>
                <div className="customers_body">
                    <div className="customers_icon_box active_box ">
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/1.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/1.6.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/7.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/8.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/9.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/10.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="customers_icon_box">
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_1.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_2.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_3.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_4.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_5.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_6.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_7.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_8.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_9.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/2_10.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        id="promotionRFBOX"
                        className="customers_icon_box"
                    >
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3_1.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3_2.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3_3.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3_4.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.5.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.6.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.7.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.8.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.9.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="customers_icon_box">
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_1.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_2.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_3.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_4.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_5.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_6.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_7.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_8.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_9.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_10.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        id="holisticMarketingBOX"
                        className="customers_icon_box"
                    >
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5_1.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5_2.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5_3.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5_4.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini ">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5_5.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4_6.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/8.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/3.7.png"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/4.svg"
                                alt=""
                            />
                        </div>
                        <div className="customers_icon_box_mini">
                            <img
                                className="customers_icon_img"
                                src="../img/customers/5_6.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
