import { useEffect, useState } from "react";
import Fease from "../bloc/fease";
import Header from "../bloc/header";
import Modorniz from "../bloc/primerModern";
import Prich from "../bloc/prichin";
import WebStud from "../bloc/webstud";
import FosContein from "../bloc/fosSect";
import PopupFos from "../bloc/popup_fos";

export default function Main(params) {
    const [first, setfirst] = useState(0);
    const [popup, setpopup] = useState(false);
    const [them, setthem] = useState("");
    const stepEnd = () => {
        document
            .querySelectorAll(".ekr-js")
            .forEach((element) => {
                setTimeout(() => {
                    element.classList.add("active");
                    setfirst(
                        document.querySelectorAll(".ekr-js")
                            .length - 1
                    );
                }, 200);
            });
            document
            .querySelectorAll(".main_menu_but")
            .forEach((element) => {
                setTimeout(() => {
                    element.classList.add("active");
                    
                }, 200);
            });
    };
    const stepsGo = () => {
        if (
            !(
                first + 1 >
                document.querySelectorAll(".ekr-js")
                    .length -
                    1
            )
        ) {
            setfirst(first + 1);
        }
        document
            .querySelectorAll(".ekr-js")
            [first].classList.add("active");
        document
            .querySelectorAll(".main_menu_but")
            [first].classList.add("active");
    };
    const stepsBec = () => {
        if (!(first - 1 < 0)) {
            setfirst(first - 1);
        }
        document
            .querySelectorAll(".ekr-js")
            [first].classList.remove("active");
        document
            .querySelectorAll(".main_menu_but")
            [first].classList.remove("active");
    };
    useEffect(() => {
        let scroll = 5;

        window.onwheel = (e) => {
            if (e.deltaY > 0 || scroll < 0) {
                scroll++;
                if (scroll > 10) {
                    stepsGo();
                    scroll = 5;
                }
            } else if (!(e.deltaY > 0)) {
                scroll--;
                if (scroll < 2) {
                    stepsBec();
                }
            }
        };

        // let touchStartPosX = 0;
        // window.addEventListener('touchmove', (e) => {
        //   const currentPageX = Math.round(e.changedTouches[0].screenY);
        //   if (touchStartPosX === currentPageX) return;

        //   if (touchStartPosX - currentPageX > 0) {
        //     scroll++;
        //     if (scroll > 10) {
        //         stepsGo();
        //         scroll = 5;
        //     }
        //   } else {
        //     scroll--;
        //     if (scroll < 2) {
        //         stepsBec();
        //         scroll = 5;

        //     }
        //   }
        //   touchStartPosX = currentPageX;
        // });
    }, [first]);
    function stepCount({ id }) {
        document
            .querySelectorAll(".ekr-js")
            .forEach((element) => {
                element.classList.remove("active");
            });
        document
            .querySelectorAll(".main_menu_but")
            .forEach((element) => {
                element.classList.remove("active");
            });
        for (let i = 0; i < id; i++) {
            document
                .querySelectorAll(".ekr-js")
                [i].classList.add("active");
            document
                .querySelectorAll(".main_menu_but")
                [i].classList.add("active");
        }
        setfirst(id-1)
    }
    return (
        <main>
            {/* <a
                    href="#fease"
                    onClick={() => stepCount({ id: 0 })}
                    className="main_menu_but e1"
                >
                    Главная
                </a> */}
            <a
                href="#modern"
                onClick={() => stepCount({ id: 1 })}
                className="main_menu_but e2"
            >
                <div className="tab-head">С нами работают</div>
                <div className="tab-head-bottom"></div>
            </a>
            <a
                href="#prich"
                onClick={() => stepCount({ id: 2 })}
                className="main_menu_but e3"
            >
                <div className="tab-head">Почему мы ?</div>
                <div className="tab-head-bottom"></div>
            </a>
            <a
                href="#webstud"
                onClick={() => stepCount({ id: 3 })}
                className="main_menu_but e4"
            >
                <div className="tab-head">О нас</div>
                <div className="tab-head-bottom"></div>
            </a>
            <a
                href="#fos"
                onClick={() => stepCount({ id: 4 })}
                className="main_menu_but e5"
            >
                <div className="tab-head">Связаться с нами</div>
                <div className="tab-head-bottom"></div>
            </a>
            <section className="hellov">
                <Header />
                <div className="container">
                    <Fease setthem={setthem} setpopup={setpopup} />
                </div>
            </section>
            <Modorniz stepEnd={stepEnd} />
            <Prich setthem={setthem} setpopup={setpopup}/>
            <WebStud setthem={setthem} setpopup={setpopup}/>
            <FosContein />
            <PopupFos them={them} popup={popup} setpopup={setpopup}/>
            <svg version="1.1">
                <defs>
                    <filter id="fancy-goo">
                        <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="9"
                            result="blur"
                        />
                        <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                            result="goo"
                        />
                        <feComposite
                            in="SourceGraphic"
                            in2="goo"
                            operator="atop"
                        />
                    </filter>
                </defs>
            </svg>
        </main>
    );
}
