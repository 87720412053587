export default function Header(params) {
    return (
        <header className="header">
            <div className="container">
                <div className="header_content">
                    
                    <a href="https://agatech.ru/">
                    <img
                        className="header_logo"
                        src={
                            process.env.PUBLIC_URL +
                            "/logo.png"
                        }
                        alt=""
                    />
                    </a>
                    {/* <button className="header_button">
                        Хочу бесплатный макет
                    </button> */}
                    <marquee className="header_strok">
                        Акция на модернизацию с 60% скидкой

                    </marquee>
                    <div className="header_contakt">
                    <a
                        className="header_tel"
                        href="tel:+79272705330"
                    >
                        <img
                        src={
                            process.env.PUBLIC_URL +
                            "./img/tel.png"
                        }
                        alt=""
                    />
                       <span>
                       8(927)270-53-30
                       </span>
                    </a>
                    <div className="header_chat">
                    <img
                        className="header_chat"
                        src={
                            process.env.PUBLIC_URL +
                            "./img/chat.png"
                        }
                        alt=""
                    />
                    <div className="header_chat_list">
                   <a href="">
                   <img
                        src={
                            process.env.PUBLIC_URL +
                            "./img/watsapp.png"
                        }
                        alt=""
                    />
                   </a>
                   <a href="">
                   <img
                        src={
                            process.env.PUBLIC_URL +
                            "./img/tg.png"
                        }
                        alt=""
                    />
                   </a>
                   <a href="https://vk.com/agatech_rus">
                   <img
                        src={
                            process.env.PUBLIC_URL +
                            "./img/vk.png"
                        }
                        alt=""
                    />
                   </a>
                   
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
