export default function Footer(params) {
    return (
        <footer className="footer">
            <a className="footer_aderes" href="">ул. Ленина, д. 89, 4 этаж 432017</a>
            <a href="https://agatech.ru/">
                <img
                    className="header_logo"
                    src={
                        process.env.PUBLIC_URL + "/logo.png"
                    }
                    alt=""
                />
            </a>
            <a className="footer_tel" href="tel:+79272705330">8(927)270-53-30</a>
        </footer>
    );
}
