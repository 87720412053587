import { useEffect, useState } from "react";
import IMgsi from "./mg";

export default function Fease({ setthem, setpopup }) {
    const [step, setstep] = useState(0);
    const [progress, setprogress] = useState(false);

    let position = [
        {
            top: "-63px",
            left: "115px",
        },
        {
            top: "121px",
            left: "371px",
        },
        {
            top: "321px",
            left: "75px",
        },
        {
            top: "121px",
            left: "-121px",
        },
    ];


    const textCati = [
        "Увеличьте охват аудитории с помощью мобильной версии вашего сайта.",
        "Перезапустим ваш бренд с ярким, функциональным и современным дизайном! ",
        "Повысьте присутствие вашего сайта в поисковой системе Яндекс с помощью нашей оптимизации и продвижения.",
        "Ускорьте загрузку вашего сайта с помощью наших профессиональных услуг. ",
    ]

    const words = [
        "Отсутствует <br/> мобильная версия?",
        "Устарел  дизайн? <br/><br/>",
        "Сайта нет в <br/> Яндексе?",
        "Медленно грузится <br/> сайт?",
    ];
    const fosesInfo = [
        "<span class='textColor'>БЕСПЛАТНО</span> макет мобильной версии за 4 часа",
        "<span class='textColor'>БЕСПЛАТНО</span> макет сайта за 8 часов",
        "Вывести сайт на первые строки Яндекса. <span class='textColor'>ГАРАНТИЯ</span>",
        "Получить план и смету модернизации сайта. <span class='textColor'>БЕСПЛАТНО</span>",
    ];
    let slidetimer;
    useEffect(() => {
        slidetimer = setTimeout(() => {
            if (step + 1 > 3) {
                setstep(0);
            } else {
                setstep(step + 1);
            }
        }, 8000);
        positioniren();
    }, [step]);
    function positioniren(params) {
        let block = document.querySelectorAll(
            ".fease_karusel_element"
        );
        block.forEach((e, i) => {
            let index = (step + i) % block.length;
            block[index].classList.remove("active");

            block[index].style.top = position[i].top;
            block[index].style.left = position[i].left;
            if (i === 3) {
                block[index].classList.add("active");
            }
        });
    }

    // function timer(params) {
    //     if (params) {
    //         setstep(params);
    //         clearInterval(slidetimer);
    //     }
    // }
    return (
        <div id="fease" className="fease">
            <div className="fease_content">
                <p className="fease_content_t2">
                    Акция на{" "}
                    <span className="videlBlu">
                        модернизацию
                    </span>
                </p>
                <div
                    id="flip"
                    className="fease_content_textSlog"
                >
                    {words.map((e, i) => (
                        <div
                            key={i}
                            dangerouslySetInnerHTML={{
                                __html: e,
                            }}
                            className={
                                step === i
                                    ? "fease_content_t1 active"
                                    : "fease_content_t1"
                            }
                        ></div>
                    ))}
                    
                </div>
                    <div id="flip"
                    className="fease_content_textSlog textmin" >
                    {textCati.map((e, i) => (
                        <div
                            key={i}
                            dangerouslySetInnerHTML={{
                                __html: e,
                            }}
                            className={
                                step === i
                                    ? "fease_content_t1 fease_content_t3 active"
                                    : "fease_content_t1 fease_content_t3"
                            }
                        ></div>
                    ))}
                    </div>
                
                <button
                    onClick={() => {
                        setthem(fosesInfo[step]);
                        setpopup(true);
                    }}
                    className="fease_content_button"
                >
                    Оставить заявку <span>&#8594;</span>
                </button>
            </div>
            <div className="fease_karusel">
                <IMgsi
                    left={"-25%"}
                    top={"2%"}
                    img={
                        <img
                            className="-im1"
                            src={
                                process.env.PUBLIC_URL +
                                "./img/fase/moln.png"
                            }
                            alt=""
                        />
                    }
                />
                <IMgsi
                    left={"-8%"}
                    top={"73%"}
                    img={
                        <img
                            className="-im2"
                            src={
                                process.env.PUBLIC_URL +
                                "./img/fase/decor.png"
                            }
                            alt=""
                        />
                    }
                />
                <IMgsi
                    left={"91%"}
                    top={"59%"}
                    img={
                        <img
                            className="-im3"
                            src={
                                process.env.PUBLIC_URL +
                                "./img/fase/moln.png"
                            }
                            alt=""
                        />
                    }
                />
                <IMgsi
                    left={"87%"}
                    top={"10%"}
                    img={
                        <img
                            className="-im4"
                            src={
                                process.env.PUBLIC_URL +
                                "./img/fase/decor.png"
                            }
                            alt=""
                        />
                    }
                />

                <div className="fease_karusel-krug">
                    <div className="fease_karusel_element -el1">
                        <div className="fease_karusel_element_fase-content">
                            <p className="fease_karusel_element_fase-content_name">
                               Устаревший дизайн ?
                            </p>
                            <img
                                className="fease_karusel_element_fase-content_img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/fase/el1.png"
                                }
                                alt=""
                            />
                        </div>
                        <div className="fease_karusel_element_fase-footer">
                            <img
                                className="fease_karusel_element_fase-footer_menu"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/men.png"
                                }
                                alt=""
                            />
                            <div className="fease_karusel_element_fase-footer-activeContent">
                                <div className="fease_karusel_element_fase-footer-activeContent_head">
                                    <button>
                                        За 3 дня
                                    </button>
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "./img/fase/gal.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <p>
                                    Обновим дизайн, движок и
                                    наполнение вашего сайта
                                    за 3 дня
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fease_karusel_element -el2">
                        <div className="fease_karusel_element_fase-content">
                            <p className="fease_karusel_element_fase-content_name">
                                Сайта нет <br /> в Яндексе ?
                            </p>
                            <img
                                className="fease_karusel_element_fase-content_img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/fase/el1.png"
                                }
                                alt=""
                            />
                        </div>
                        <div className="fease_karusel_element_fase-footer">
                            <img
                                className="fease_karusel_element_fase-footer_menu"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/men.png"
                                }
                                alt=""
                            />
                            <div className="fease_karusel_element_fase-footer-activeContent">
                                <div className="fease_karusel_element_fase-footer-activeContent_head">
                                    <button>
                                        За 5-7 дней
                                    </button>
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "./img/fase/gal.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <p>
                                    Доведем SEO до идеала и
                                    выведем сайт на первые
                                    строки в Яндексе
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fease_karusel_element -el3">
                        <div className="fease_karusel_element_fase-content">
                            <p className="fease_karusel_element_fase-content_name">
                                Медленно грузится сайт?
                            </p>
                            <img
                                className="fease_karusel_element_fase-content_img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/fase/el2.png"
                                }
                                alt=""
                            />
                        </div>
                        <div className="fease_karusel_element_fase-footer">
                            <img
                                className="fease_karusel_element_fase-footer_menu"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/men.png"
                                }
                                alt=""
                            />
                            <div className="fease_karusel_element_fase-footer-activeContent">
                                <div className="fease_karusel_element_fase-footer-activeContent_head">
                                    <button>
                                        За 4 часа
                                    </button>
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "./img/fase/gal.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <p>
                                    Увеличим скорость
                                    загрузки вашего сайта и
                                    уменьшим уровень
                                    отказов.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fease_karusel_element -el4">
                        <div className="fease_karusel_element_fase-content">
                            <p className="fease_karusel_element_fase-content_name">
                                Отсутствует <br />
                                мобильная <br /> версия?
                            </p>
                            <img
                                className="fease_karusel_element_fase-content_img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/fase/el3.png"
                                }
                                alt=""
                            />
                        </div>
                        <div className="fease_karusel_element_fase-footer">
                            <img
                                className="fease_karusel_element_fase-footer_menu"
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/men.png"
                                }
                                alt=""
                            />
                            <div className="fease_karusel_element_fase-footer-activeContent">
                                <div className="fease_karusel_element_fase-footer-activeContent_head">
                                    <button>
                                        За 4 дня
                                    </button>
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "./img/fase/gal.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <p>
                                    Создадим мобильную
                                    версию за 4 дня В
                                    подарок макет мобильной
                                    версии
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ImgHov({ top, left, img, clDop }) {
    if (document.querySelector(".imgHov")) {
        let container = document.querySelector(".imgHov");
        let inner = document.querySelector(".imgHov img");

        let onMouseEnterHandler = function (event) {
            update(event);
        };
        let onMouseLeaveHandler = function () {
            inner.style = "";
        };
        let onMouseMoveHandler = function (event) {
            if (isTimeToUpdate()) {
                update(event);
            }
        };

        container.onmouseenter = onMouseEnterHandler;
        container.onmouseleave = onMouseLeaveHandler;
        container.onmousemove = onMouseMoveHandler;
        console.log(container);
        let counter = 0;
        let updateRate = 10;
        let isTimeToUpdate = function () {
            return counter++ % updateRate === 0;
        };

        let mouse = {
            _x: 0,
            _y: 0,
            x: 0,
            y: 0,
            updatePosition: function (event) {
                let e = event || window.event;
                this.x = e.clientX - this._x;
                this.y = (e.clientY - this._y) * -1;
            },
            setOrigin: function (e) {
                this._x =
                    e.offsetLeft +
                    Math.floor(e.offsetWidth / 2);
                this._y =
                    e.offsetTop +
                    Math.floor(e.offsetHeight / 2);
            },
            show: function () {
                return "(" + this.x + ", " + this.y + ")";
            },
        };
        mouse.setOrigin(container);

        let update = function (event) {
            mouse.updatePosition(event);
            updateTransformStyle(
                (mouse.y / inner.offsetHeight).toFixed(2),
                (mouse.x / inner.offsetWidth).toFixed(2)
            );
        };

        let updateTransformStyle = function (x, y) {
            let style =
                "rotateX(" +
                x * 15 +
                "deg) rotateY(" +
                y +
                "deg)";
            inner.style.transform = style;
            inner.style.webkitTransform = style;
            inner.style.mozTransform = style;
            inner.style.msTransform = style;
            inner.style.oTransform = style;
        };
    }

    return (
        <div
            style={{ top: top, left: left }}
            className="imgHov"
        >
            <img
                className={clDop}
                src={
                    process.env.PUBLIC_URL +
                    "./img/fase/" +
                    img
                }
                alt=""
            />
        </div>
    );
}
