import Footer from "./footer";
import ReactInputMask from "react-input-mask";
import axios from "axios";
import { useState } from "react";

export default function FosContein(params) {
    const [otprav, setotprav] = useState(false);
    function MailGo(e) {
        e.preventDefault();
        const element = {
            name: e.target[0].value,
            tel: e.target[1].value,
            adres: e.target[2].value,
            type: e.target[3].value,
        };
        if (e.target[1].value.includes("_")) {
            e.target[1].focus();
        } else {
            axios({
                method: "POST",
                url: "https://upgrade.agatech.ru/mail/mail.php",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(element),
            })
                .then((response) => {
                    setotprav(true);

                    setTimeout(() => {
                        setotprav(false);
                    }, 3000);
                })
                .catch((error) => {});
        }
    }
    return (
        <section id="fos" className="fos ekr-js">
            <div className="container">
                <h2 className="h2">
                    Хотите сделать макет{" "}
                    <span className="videlBlu">
                        нового сайта
                    </span>{" "}
                    ?
                </h2>
                <p className="fos-ps">
                    Для самых смелых идей
                </p>
                {otprav ? (
                    <>
                        <div className="fos-form">
                            <p className="fos-ps">
                                Ваша заявка успешно
                                отправленна мы свяжемся с
                                вами в ближайшее время !
                            </p>
                        </div>
                    </>
                ) : (
                    <form
                        onSubmit={MailGo}
                        className="fos-form"
                        action=""
                    >
                        <div className="fos-form_inputBox">
                            <input
                                type="text"
                                name="name"
                                placeholder="Имя"
                            />
                        </div>
                        <div className="fos-form_inputBox">
                            <ReactInputMask
                                className="fosContact_inputBox "
                                name="tel"
                                placeholder="Телефон"
                                mask="+7 (999) 999 99 99"
                                type="text"
                                required
                            />
                        </div>
                        <div className="fos-form_inputBox">
                            <input
                                type="text"
                                name="site"
                                placeholder="Адрес сайта"
                            />
                        </div>
                        <input
                            type="hidden"
                            name="type"
                            defaultValue={
                                "Хотите сделать макет нового сайта"
                            }
                        />
                        <button className="fease_content_button">
                            хочу бесплатный макет{" "}
                            <span>→</span>
                        </button>
                    </form>
                )}
                <div className="fos_user">
                    <a href="tel:89041816004">
                        <div className="contactBlock ">
                            <img
                                loading="lazy"
                                className="agentPhoto"
                                src="img/users/astashenkov.jpg"
                                alt=""
                            />
                            <div className="agent">
                                <p className="locationAgentStatus">
                                    Руководитель компании
                                </p>

                                <p className="agentName">
                                    Асташенков Глеб
                                </p>
                                <p className="agentPhone">
                                    +7 (904) 181 60 04
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="tel:89272705330">
                        <div className="contactBlock ">
                            <img
                                loading="lazy"
                                className="agentPhoto"
                                src="img/users/utilina.jpg"
                                alt=""
                            />
                            <div className="agent">
                                <p className="locationAgentStatus">
                                    Отдел по работе с
                                    клиентами
                                </p>

                                <p className="agentName">
                                    Улитина Ирина
                                </p>
                                <p className="agentPhone">
                                    +7 (927) 270 53 30
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <Footer />
            </div>
        </section>
    );
}
