import axios from "axios";
import { useState } from "react";
import ReactInputMask from "react-input-mask";

export default function PopupFos({
    popup,
    setpopup,
    them,
}) {
    const [otprav, setotprav] = useState(false);
    function MailGo(e) {
        e.preventDefault();
        const element = {
            name: e.target[0].value,
            tel: e.target[1].value,
            adres: e.target[2].value,
            type: e.target[3].value,
        };
        if (e.target[1].value.includes("_")) {
            e.target[1].focus();
        } else {
            axios({
                method: "POST",
                url: "https://upgrade.agatech.ru/mail/mail.php",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(element),
            })
                .then((response) => {
                        setotprav(true);
                        setTimeout(() => {
                            setpopup(false);
                            setotprav(false);

                        }, 3000);
    
                })
                .catch((error) => {});
        }
    }

    return (
        <div
            onClick={() => setpopup(false)}
            className={
                popup ? "popupFos active" : "popupFos"
            }
        >
            <div className="popupFos_content">
                {otprav ? 
                <>
                <div>
                   <p className="h3">
                   Ваша заявка успешно отправленна мы свяжемся с вами в ближайшее время !
                   </p>
                </div>
                </>  : 
                <form
                onSubmit={MailGo}
                onClick={(e) => e.stopPropagation()}
                className="popupFos_content"
                action=""
            >
                <p
                    className="popupFos_title t16"
                    dangerouslySetInnerHTML={{
                        __html: them,
                    }}
                ></p>
                <div className="fos-form_inputBox">
                    <input
                        type="text"
                        name="name"
                        placeholder="Имя"
                    />
                </div>
                <div className="fos-form_inputBox">
                    <ReactInputMask
                        className="fosContact_inputBox "
                        name="tel"
                        placeholder="Телефон"
                        mask="+7 (999) 999 99 99"
                        type="text"
                        required
                    />
                </div>
                {them !== "Стать партнером" && (
                    <>
                    <div className="fos-form_inputBox">
                        <input
                            type="text"
                            name="site"
                            placeholder="Адрес сайта"
                        />
                    </div>
                <input type="hidden" name="type" defaultValue={them}/>
                    
                    </>
                )}
                <input type="hidden" name="type" defaultValue={"Сделать макет нового сайта"}/>
                <button className="fease_content_button">
                    Отправить заявку <span>→</span>
                </button>
            </form>  
            }
            </div>
        </div>
    );
}
