export default function WebStud({ setthem, setpopup }) {
    return (
        <section id="webstud" className="webstud ekr-js">
            <div className="container">
                <h2 className="h2">
                    Веб-студия{" "}
                    <span className="sinColor">
                        AGATECH
                    </span>
                </h2>
                <div className="webstud_content">
                        <div className="webstud_content_text">
                            <p className="webstud_content_text-te">
                                <b>
                                    Получила
                                    несколько престижных
                                    наград:
                                </b>
                            </p>
                            <p className="webstud_content_text-te">
                                Наша команда эффективно
                                работает с задачами,
                                поставленными заказчиком и
                                предлагает индивидуальные
                                решения.
                            </p>
                            <div className="webstud_content_text-nagrad">
                                <div className="webstud_content_text-nagrad_item">
                                    <div className="webstud_content_text-nagrad_item_imgBox">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "./img/nagrad/1.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <p className="webstud_content_text-nagrad_item_text">
                                        Персона года по
                                        версии журнала
                                        “Деловое обозрение”
                                    </p>
                                </div>
                                <div className="webstud_content_text-nagrad_item">
                                    <div className="webstud_content_text-nagrad_item_imgBox">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "./img/nagrad/2.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <p className="webstud_content_text-nagrad_item_text">
                                        Премию “Золотой
                                        дракон” в номинации
                                        “Маркетинговое
                                        агентство года”
                                    </p>
                                </div>
                                <div className="webstud_content_text-nagrad_item">
                                    <div className="webstud_content_text-nagrad_item_imgBox">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "./img/nagrad/3.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <p className="webstud_content_text-nagrad_item_text">
                                        Первая ежегодная
                                        премия «Вместе»
                                    </p>
                                </div>
                                <div className="webstud_content_text-nagrad_item">
                                    <div className="webstud_content_text-nagrad_item_imgBox">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "./img/nagrad/4.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <p className="webstud_content_text-nagrad_item_text">
                                        Бренд года по версии
                                        журнала “Деловое
                                        обозрение”
                                    </p>
                                </div>
                            </div>
                            <button  onClick={()=> {setthem("Обсудить проект");setpopup(true);}} className="fease_content_button">
                    Обсудить проект<span>→</span>
                </button>
                        </div>

                        <div className="webstud_content-imgGl">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/nagrad/gl.png"
                                }
                                alt=""
                            />
                        </div>
                </div>
                
            </div>
        </section>
    );
}
