export default function Prich({ setthem, setpopup }) {
    return (
        <section id="prich" className="prich ekr-js">
            <div className="container">
                <h2 className="h2">
                    Шесть причин заказать <br />сайт в {" "}
                    <span className="videlBlu">
                    AGATECH
                    </span>
                </h2>

                <div className="prich_content-list">
                    <div className="prich_content-item " style={{'--i':"1"}}>
                        <div className="prich_content-item_head">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/prich/1.png"
                                }
                                alt=""
                            />
                        </div>
                        <p className="prich_content-item_text">
                            Приедем сами, проведем
                            бесплатную консультацию
                        </p>
                    </div>
                    <div className="prich_content-item " style={{"--i":"2"}}>
                        <div className="prich_content-item_head">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/prich/2.png"
                                }
                                alt=""
                            />
                        </div>
                        <p className="prich_content-item_text">
                            Анализируем весь процесс продажи
                            товара, выявляем сильные и
                            слабые стороны
                        </p>
                    </div>
                    <div className="prich_content-item"  style={{"--i":"3"}}>
                        <div className="prich_content-item_head">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/prich/3.png"
                                }
                                alt=""
                            />
                        </div>
                        <p className="prich_content-item_text">
                            Командная работа над проектом!
                        </p>
                    </div>
                    <div className="prich_content-item"  style={{"--i":"4"}}>
                        <div className="prich_content-item_head">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/prich/4.png"
                                }
                                alt=""
                            />
                        </div>
                        <p className="prich_content-item_text">
                            Индивидуальные условия оплаты
                            (рассрочка, отсрочки, бартер)
                        </p>
                    </div>
                    <div className="prich_content-item"  style={{"--i":"5"}}>
                        <div className="prich_content-item_head">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/prich/5.png"
                                }
                                alt=""
                            />
                        </div>
                        <p className="prich_content-item_text">
                            Разработаем уникальный дизайн
                        </p>
                    </div>
                    <div className="prich_content-item"  style={{"--i":"6"}}>
                        <div className="prich_content-item_head">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "./img/prich/6.png"
                                }
                                alt=""
                            />
                        </div>
                        <p className="prich_content-item_text">
                            Ваш проект - Ваша собственность!
                            Передача прав заказчику
                        </p>
                    </div>
                </div>
                <button  onClick={()=> {setthem("Заказать сайт");setpopup(true);}} className="fease_content_button">
                Заказать сайт<span>→</span>
                </button>
            </div>
        </section>
    );
}
